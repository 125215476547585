@font-face {
  font-family: 'Google Sans';
  src: local('Google Sans'), url('../public/fonts/GoogleSans-Regular.ttf') format('truetype');
}
body {
  font-family: 'Google Sans', serif !important;
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-thumb {
  background-color: #acaeb1;
  border-radius: 10px;
}

.ant-pagination-item-link {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.ant-pagination-item-link {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.ant-table-tbody > tr > td {
  border-bottom: none !important;
}
.table-row-dark {
  background-color: #f3f3f3;
}
.text-primary-2 {
  color: #2e6bb4;
}
.display-none {
  display: none;
}
.pointer {
  cursor: pointer;
}
.line {
  height: 1px;
  background-color: #c7c7c7;
  margin: 20px 0 20px 0;
}
.line-1 {
  height: 1px;
  background-color: #c7c7c7;
}
.ant-modal-header {
  text-align: center;
}
.ant-modal-title {
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 25px !important;
}
.ant-modal-footer {
  text-align: center !important;
}
.ant-modal-footer button {
  margin: 10px 0 10px 0;
}
.text-1 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}
.text-2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}
.button {
  border: none;
  background: #2e6bb4;
  border-radius: 4px;
  color: #ffffff;
  padding: 8px 20px 8px 20px;
}
.button:hover {
  background-color: #204a7d;
}
.border-r {
  border-radius: 23px;
}
.button-1 {
  border: none;
  background: #01ce89;
  border-radius: 4px;
  color: #ffffff;
  padding: 8px 20px 8px 20px;
}
.button-1:hover {
  background-color: #00905f;
}
.button-2 {
  border: none;
  background: #d02828;
  border-radius: 4px;
  color: #ffffff;
  padding: 8px 20px 8px 20px;
}
.button-2:hover {
  background-color: #911c1c;
}
.button-3 {
  border: none;
  background: #f3f3f3;
  border-radius: 4px;
  color: #333333;
  padding: 8px 20px 8px 20px;
}
.button-3:hover {
  background-color: #aaaaaa;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mr-12 {
  margin-right: 12px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-25 {
  margin-right: 25px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-40 {
  margin-left: 40px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-30 {
  margin-top: 30px;
}
.width-50 {
  width: 50%;
}

@font-face {
  font-family: 'GoogleSans-Black';
  src: local('GoogleSans-Black'), url(./fonts/GoogleSans-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'GoogleSans-BlackItalic';
  src: local('GoogleSans-BlackItalic'), url(./fonts/GoogleSans-BlackItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'GoogleSans-Bold';
  src: local('GoogleSans-Bold'), url(./fonts/GoogleSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'GoogleSans-BoldItalic';
  src: local('GoogleSans-BoldItalic'), url(./fonts/GoogleSans-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'GoogleSans-Italic';
  src: local('GoogleSans-Italic'), url(./fonts/GoogleSans-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'GoogleSans-Light';
  src: local('GoogleSans-Light'), url(./fonts/GoogleSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'GoogleSans-LightItalic';
  src: local('GoogleSans-LightItalic'), url(./fonts/GoogleSans-LightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'GoogleSans-Medium';
  src: local('GoogleSans-Medium'), url(./fonts/GoogleSans-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'GoogleSans-MediumItalic';
  src: local('GoogleSans-MediumItalic'), url(./fonts/GoogleSans-MediumItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'GoogleSans-Regular';
  src: local('GoogleSans-Regular'), url(./fonts/GoogleSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'GoogleSans-Thin';
  src: local('GoogleSans-Thin'), url(./fonts/GoogleSans-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'GoogleSans-ThinItalic';
  src: local('GoogleSans-ThinItalic'), url(./fonts/GoogleSans-ThinItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Gotham-Medium';
  src: local('Gotham-Medium'), url(./fonts/Gotham-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Gotham-Thin';
  src: local('Gotham-Thin'), url(./fonts/Gotham-Thin.ttf) format('truetype');
}
